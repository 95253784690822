<template>
    <main>
      <b-overlay :show="loading">
        <validation-observer ref="form">
          <b-card>
              <b-row>
            <b-col sm="12" md="6">
              <b-form-group v-if="!isInvoiceAvailable">
                <label for="">Pilih No.Procurement</label>
                <v-select
                  v-model="selectedInvoice"
                  :options="pengadaans"
                  label="text"
                />
              </b-form-group>
              <p class="mb-0" v-else>
                <strong>ID {{ pengadaan.id }} No. Procurement: {{ pengadaan.no }}</strong>
              </p>
            </b-col>
            <b-col sm="12" md="6">
              <b-form-group class="d-flex justify-content-between">
                <b-button
                  class="mt-2"
                  @click.prevent="$router.push(`/asset-penyimpanan/add-empty`)"
                  variant="outline-primary"
                >
                <span class="align-middle"><feather-icon icon="PlusIcon" /> Tambah Penyimpanan Kosong</span>
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
          </b-card>
          <!-- two card -->
          <b-row>
            <!-- List barang di penerimaan -->
            <b-col sm="12" md="6">
              <b-card>
                <b-table
                  responsive
                  striped
                  bordered
                  :fields="fields"
                  :items="assets"
                >
                  <template #cell(action)="{ item }">
                    <b-form-radio
                      v-model="selectedBarang"
                      :value="item"
                      :name="`asset`"
                    />
                  </template>
                  <template #cell(nama)="{ item }">
                    {{ item.asset ? item.asset.nama : "-" }}
                  </template>
                  <template #cell(satuan)="{ item }">
                    {{
                      item.asset && item.asset.satuan
                        ? item.asset.satuan.satuan
                        : "-"
                    }}
                  </template>
                </b-table>
              </b-card>
            </b-col>
            <!-- Penempatan gudang -->
            <b-col sm="12" md="6">
              <b-card>
                <form>
                  <b-form-group v-if="!isInvoiceAvailable" hidden>
                      <label for="">Pilih Procurement</label>
                      <v-select
                        :options="invoiceList"
                        v-model="form.pengadaan_id"
                        :reduce="(option) => option.value"
                        label="text"
                      />
                  </b-form-group>
                  <b-form-group v-if="myGudang">
                      <label for="">Pilih Gudang</label>
                      <b-form-input disabled :value="myGudang.nama_gudang"></b-form-input>
                  </b-form-group>
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="blok"
                      rules="required"
                    >
                      <label for="">Pilih Lokasi Aset</label>
                      <v-select
                        :options="bloks"
                        v-model="form.blok_id"
                        :required="!form.blok_id"
                        :reduce="(option) => option.value"
                        label="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  
                  <b-row>
                    <b-col cols="12">
                      <b-form-group>
                        <label for="nilai">Jumlah</label>
                        <b-form-input
                          v-model="form.jumlah"
                          type="number"
                          id="nilai"
                          disabled
                          placeholder="nilai"
                        />
                      </b-form-group>
                    </b-col>
                    
                  </b-row>
  
                  <b-form-group>
                    <b-button @click.prevent="addBarang" variant="primary"
                      >Tambah</b-button
                    >
                  </b-form-group>
                </form>
              </b-card>
            </b-col>
            <!-- Barang yang sudah disimpan -->
            <b-col cols="12">
              <b-card>
                <b-table
                  :fields="fieldsAddedItems"
                  bordered
                  striped
                  responsive
                  :items="addedItems"
                >
                  <template #cell(no)="row">
                    {{ row.index + 1 }}
                  </template>
                  <template #cell(lokasi)="{ item }">
                    <strong>
                      {{ item.lokasi }}
                    </strong>
                  </template>
                  <template #cell(action)="row">
                    <b-link
                      class="text-danger"
                      @click.prevent="addedItems.splice(row.index, 1)"
                    >
                      <feather-icon size="24" icon="XCircleIcon"></feather-icon>
                    </b-link>
                  </template>
                </b-table>
                <div class="d-flex justify-content-end">
                  <b-button variant="primary" @click.prevent="submit">
                    Simpan
                  </b-button>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </validation-observer>
      </b-overlay>
    </main>
  </template>
  <script>
  import {
    BTable,
    BOverlay,
    BFormSelect,
    BFormRadio,
    BLink,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BFormCheckbox,
    BButton,
    BFormInput,
  } from "bootstrap-vue";
  import vSelect from "vue-select";
  import { extend } from "vee-validate";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import { required } from "vee-validate/dist/rules";
  extend("required", {
    ...required,
    message: "This field is required",
  });
  extend("positive", (value) => {
    return value > 0;
  });
  
  export default {
    components: {
      vSelect,
      BFormSelect,
      BLink,
      ValidationObserver,
      ValidationProvider,
      BOverlay,
      BFormGroup,
      BFormRadio,
      BTable,
      BRow,
      BCol,
      BCard,
      BFormCheckbox,
      BButton,
      BFormInput,
    },
    watch: {
        selectedBarang: {
            handler(val) {
                if(val.jumlah) {
                    this.form.jumlah = parseInt(val.sisa)
                }
            },
            deep: true
        },
      nilai(value2) {
        let hasil = value2 * this.id_satuan;
        this.form.jumlah = hasil;
      },
      id_satuan(value) {
        let hasil = value * this.nilai;
        this.form.jumlah = hasil;
      },
      "form.gudang_id"(gudang_id) {
        this.getBlok(gudang_id);
      },
      async selectedInvoice(pengadaan) {
        if(pengadaan.data) {
            this.pengadaan = pengadaan
            const {id} = pengadaan.data
            // this.assets = rincian
            this.loading = true
            this.assets = await this.$store.dispatch('asset/getRincianProcurement', {pengadaan_id: id})
            this.loading = false
        }
      },
    },
    data: () => ({
        pengadaans: [],
      params: {},
      loading: false,
      selectedInvoice: null,
      selectedBarang: null,
      addedItems: [],
      fieldsAddedItems: [
        { key: "no", label: "No" },
        { key: "asset", label: "Nama Aset" },
        { key: "jumlah", label: "Jumlah" },
        { key: "lokasi", label: "Lokasi" },
        { key: "action", label: "#" },
      ],
      fields: [
        { key: "action", label: "#" },
        { key: "nama", label: "Aset" },
        { key: "satuan", label: "Satuan" },
        { key: "jumlah", label: "Jumlah" }
      ],
      bloks: [],
      pengadaan: {
        id: null,
        no: null,
      },
      raks: [],
      lacis: [],
      palets: [],
      gudangs: [],
      assets: [],
      form: {
        pengadaan_id: null,
        gudang_id: null,
        blok_id: null,
        jumlah: 0
      },
      invoiceList: [],
      id_satuan: 0,
      hasil: 0,
      nilai: 0,
    }),
    methods: {
      submit() {
        
        this.addedItems.map((item) => {
          delete item.asset
          delete item.lokasi
          item.pengadaan_id = item.p_id // rincian pengadaan id
          item.gudang_id = this.myGudang.id
        });
        let payload = [];
        Object.assign(payload, this.addedItems);
  
        this.loading = true;
        this.$store
          .dispatch("asset/savePenyimpanan", payload)
          .then(() => {
            this.loading = false;
            this.displaySuccess({
              message: "Aset berhasil ditempatkan!",
            });
  
            setTimeout(() => {
              this.$router.push("/asset-penyimpanan");
            }, 1000);
          })
          .catch((e) => {
            this.loading = false;
            this.displayError(e);
            return false;
          });
      },
      addBarang() {
        if (this.form.jumlah > this.selectedBarang.sisa) {
          this.$bvToast.toast(
            "Jumlah yang diisi tidak boleh lebih dari Sisa jumlah barang",
            {
              title: `Peringatan`,
              variant: "danger",
              solid: true,
              toaster: "b-toaster-bottom-right",
            }
          );
          // this.displayError({
          //     message: 'Jumlah yang disimpan harus kurang dari atau sama dengan sisa stok penerimaan'
          // })
          return false;
        }
        this.$refs.form.validate()
        .then((success, failed) => {
          if (success) {
            let data = {};
            Object.assign(data, this.form);
            data.asset =
              this.selectedBarang && this.selectedBarang.asset
                ? this.selectedBarang.asset.nama
                : null;
            data.asset_id =
              this.selectedBarang && this.selectedBarang.asset
                ? this.selectedBarang.asset.id
                : null;
  
            // get lokasi
            const blok = this.bloks.find((blok) => blok.value == data.blok_id)
  
            let lokasi = blok ? `Blok: ${blok.text}` : ""
            data.lokasi = lokasi;
            data.p_id = this.selectedBarang ? this.selectedBarang.id : null
            this.addedItems.push(data);
          }
        })
        .catch(e => console.error(e));
      },
      async getBlok(gudang_id) {
        this.bloks = [];
        const params = {
            gudang_id: this.myGudang.id
        }
        const bloks = await this.$store.dispatch('asset/getBlok', params)
        this.bloks = bloks.map(blok => ({value: blok.id, text: blok.blok}))
      },
      async getProcurement() {
        const params = {order: 'desc'}
        if(this.myGudang) params.gudang_id = this.myGudang.id
        this.loading = true;
        let pengadaans = await this.$store.dispatch('asset/getProcurement', params)
        // Procurement yang belum ditempatkan dan statusnya tidak ditolak owner
        pengadaans = pengadaans.filter(item => item.selesai == 0 &&  item.status != 2)
        this.pengadaans = pengadaans.map(item => ({value: item.id, text: item.no, data: item}))
        this.loading = false
      },
    },
    computed: {
      isInvoiceAvailable() {
        return this.$route.query.pg;
      },
    },
    async created() {
      this.getProcurement();
      if(!this.myGudang) {
        this.$router.push('/asset-penyimpanan')
      }
      this.getBlok()

      if(this.isInvoiceAvailable) { // pg
        this.loading = true
        this.pengadaan = await this.$store.dispatch('asset/getProcurementById', this.$route.query.pg)
        this.assets = await this.$store.dispatch('asset/getRincianProcurement', {pengadaan_id: this.$route.query.pg})
        this.loading = false
      }
    },
  };
  </script>